import logo from './logo.svg';
import './App.css';
import "@google/model-viewer";
import model from "./model.glb";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          JustinLennox.com
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          JustinLennox.com
        </a>
      </header>
      <model-viewer src={model} ar ar-modes="webxr scene-viewer quick-look" camera-controls poster="poster.webp" shadow-intensity="1">
        {/* <div className="progress-bar hide" slot="progress-bar">
            <div className="update-bar"></div>
        </div>
        <button slot="ar-button" id="ar-button">
            View in your space
        </button>
        <div id="ar-prompt">
            <img src="https://modelviewer.dev/shared-assets/icons/hand.png" />
        </div> */}
    </model-viewer>
    </div>
  );
}

export default App;
